import Vue from "vue";
import Meta from "./meta-dados";
import Axios from "axios";
const mixin = {
  data () {
    return {
      requisicaoAberta: false,
    }
  },
  methods: {
    gerarMenssagemDeErro (erros) {
      if (typeof erros !== "object")
        return '<div class="text-left">' + erros + "</div>";
      let html = '<div class="text-left">';
      Object.keys(erros).forEach(Campo => {
        html +=
          '<div class="alert alert-danger" role="alert">' +
          '<h5 class="alert-heading">' +
          Campo +
          "</h5>" +
          '<ul class="list-group list-group-flush">';
        erros[Campo].forEach(ErroCampo => {
          html += '<li class="list-group-item">' + ErroCampo + "</li>";
        });
        html += "</ul></div>";
      });
      html += "</div>";

      return html;
    },
    verificarCampo (nomeDoCampo, formulario) {
      const campo = this.$v[formulario][nomeDoCampo];
      if (!campo.$dirty) {
        return null;
      }
      if (
        (campo.hasOwnProperty("decimal") ||
          campo.hasOwnProperty("integer") ||
          campo.hasOwnProperty("number")) &&
        campo.$model === ""
      ) {
        campo.$model = 0;
      }

      return !(campo.$invalid || campo.$model === "");
    },
    obtenhaMensagem (codigo, codigos = {}, formulario = "") {
      if (typeof codigo == "number") return Meta.obtenhaMensagem(codigo);
      if (typeof codigo == "string") return codigo;
      const campo = Object.keys(codigos).find(
        validacao => !formulario[codigo][validacao]
      );
      return campo !== undefined ? Meta.obtenhaMensagem(codigos[campo]) : "";
    },
    obtenhaIdProjeto () {
      return this.$route.params.projetoID
    },
    otenhaFundacao (codigo) {
      let fundacao = { 1: "Sapata", 2: "Tubulão", 3: "Estacas" };
      if (fundacao.hasOwnProperty(codigo))
        return fundacao[codigo];
      return "Inexistente";

    },
    obtenhaCriterioLocal () {
      let idProjeto = this.obtenhaIdProjeto();
      if (Vue.$sessionStorage.hasKey("criterio")) {
        let criterio = Vue.$sessionStorage.get("criterio");
        if (criterio.banco.projeto_id == idProjeto) return;
      }
      this.requisicaoAberta = true;
      Axios.get("/projeto/" + idProjeto + "/criterio")
        .then(resposta => {
          if (resposta.status == 200)
            Vue.$sessionStorage.set("criterio", resposta.data.retorno);
        })
        .catch(error => { })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    }
  },
  mounted () {
    this.obtenhaCriterioLocal();
  },
}


export default mixin;
