import Vue from "vue";
import Axios from "axios";

export default new class Meta {


    constructor() { }
    obtenhaMetadados() {
        Axios.get("/meta").then(Response => {
            Vue.$sessionStorage.set("estacas", Response.data.retorno.estacas);
            Vue.$sessionStorage.set("solos", Response.data.retorno.solos);
            Vue.$sessionStorage.set("mensagens", Response.data.retorno.mensagens);
        });
    }
    get obtenhaMensagens() {
        this.verificaExisteMetadado();
        return Vue.$sessionStorage.get("mensagens");
    }
    get obtenhaEstacas() {
        this.verificaExisteMetadado();
        return Vue.$sessionStorage.get("estacas")
    }
    get obtenhaSolos() {
        this.verificaExisteMetadado();
        return Vue.$sessionStorage.get("solos");
    }

    verificaExisteMetadado() {
        if (!Vue.$sessionStorage.hasKey("estacas") || !Vue.$sessionStorage.hasKey("solos") || !Vue.$sessionStorage.hasKey("mensagens"))
            this.obtenhaMetadados();
    }


    obtenhaMensagem(numero, idioma = "pt-br") {
        let mensagens = this.obtenhaMensagens;
        if (mensagens.hasOwnProperty(numero))
            return mensagens[numero][idioma];
        throw new Error("Não encontrei a mensagem com o código informado!");
    }
}
